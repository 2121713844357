<template>
  <div class="vip">

    <Head :type="1" style="background-color: #272221">


                      <template #title> <div style="color: white">会员中心</div>  </template>
                      
                    
                      <template #right>

                                      <div  v-if="level" style="color: white; text-align: center" @click="$router.push({path:'VIPRule',query:{level1:level.gold,level2:level.diamond}})" > 等级规则  </div>
                        
                      </template>

    </Head>

    <div class="main">
      <div class="main-box">

        <div class="swiper-container">

           <div class="swiper-wrapper" v-if="level">

                          <!-- 银牌 -->
                          <div class="swiper-slide" >

                                    <div class="list">

                                              <div :class="['item', 'yin', index.activeIndex !== 0 ? 'suofang' : '']">

                                              <div class="lable"  v-if="level.levelName==0"  style="color: #858ea5; border: 2px solid #858ea5">当前等级 </div>
                                              
                                              <div class="level" style="color: #999da9"  v-if="level.levelName > 0" > 当前高于该等级</div>
                                              
                                              <div class="tip" v-if="level.levelName ==0"> <span>{{ level.pointsCurrent }}</span>距离金牌会员还需{{ level.gold-level.pointsCurrent }} </div>
                                              
                                              <div class="range" v-if="level.levelName ==0">
                                              
                                              <!-- 进度条 -->
                                              <div class="li" :style="{  width: (level.pointsCurrent / level.gold) * 100 + '%' }"></div>
                                            
                                    </div>

                          </div>

          </div>

            </div>

              <!-- 金牌 -->
             <div class="swiper-slide" >

                <div class="list">

                <div :class="['item', 'jin', index.activeIndex !== 1 ? 'suofang' : '']">

                        <div class="lable"  v-if="level.levelName==1" style="color: #858ea5; border:2px solid #858ea5 " >当前等级 </div>
                   
                        <div class="level"  style="color: #9f5026" v-if="level.levelName <1" > 成长值达到{{level.upScope}}可升级</div>
                      
                        <div class="level"  style="color: #9f5026" v-if="level.levelName >1" > 当前高于该等级</div>
                        
                        <div class="tip"  v-if=" level.levelName==1 "> <span>{{ level.pointsCurrent }}</span>距离钻石会员还需{{ level.diamond-level.pointsCurrent }}</div>
                        
                        <div  class="range" v-if=" level.levelName==1" >

                        <!-- 进度条 -->
                        <div  class="li" :style="{ width: ( Number(level.pointsCurrent)  /  level.diamond) * 100 + '%'}" ></div>
                    
                  </div>
                </div>
              </div>
            </div>

            <!--  钻石 -->
             <div class="swiper-slide" >

                 <div class="list">

                      <div :class="['item', 'zhuansi', index.activeIndex !== 2 ? 'suofang' : '']">

                              <div v-if="level.levelName ==2" class="lable" style="color: #8a71bf; border: 2px solid #887cdc"> 当前等级 </div>
                              
                              <div class="level"  style="color: #8a71bf" v-if="level.levelName <2" > 成长值达到{{level.up2Scope}}可升级</div>
                                
                              <div class="level" style="color: #8a71bf" v-if="level.levelName >1" > 恭喜已是最高级别 </div>
                        
                      </div>   
                   
                 </div>
            </div>       
                 
        </div>
        
    </div>


        <div class="box">

              <!-- 银牌 -->
              <div :class="['item1']" v-show="index.activeIndex === 0"  ></div>

              <!-- 金牌 -->
              <div :class="['item2']" v-show="index.activeIndex === 1"></div>

              <!-- 钻石 -->
              <div :class="['item3']" v-show="index.activeIndex === 2"></div>

        </div>
        <div class="warp">
          <div class="title">生活服务</div>

          <div class="footer">

             <div class="left"></div>

             <div class="right">

              <div class="top" @click="junm(5)"></div>

              <div class="list list1" @click="junm(3)"></div>

              <div class="list list2" @click="junm(4)"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "../../../components/head/index.vue";
import { getLevel } from "../../../api/maker/vip";
import "swiper/dist/css/swiper.min.css";
import Swiper from "swiper/dist/js/swiper.js";
export default {
  data() {
    return {
      value: 50,
      index: 0,  // 保存轮播图数据 
      level:null, //会员信息数据

    };
  },
  created() {
      this.setRem();
      const session =
      this.$route.query.session || "9d1f5268-006f-4192-9f86-f464e023d734";
      this.$store.commit("setSession",session|| this.$route.query.session);
    try {
              // 修改状态栏背景色
              window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({ color: "#272221" }) );
       
     
              // 修改状态栏字体颜色
                window.webkit.messageHandlers.statusBarWhite.postMessage(JSON.stringify({status:1}))
              // http://192.168.0.102:9000/joolun/1/material357ff1b2-4743-4632-88cd-40494cc6a0e9.png
    } catch {

      //

    }
   
  },
  methods: {

    swiperChange(e) {
           clearInterval(this.timerId);

           let n = window.screen.width / 10;

          this.timerId = setInterval(() => {


                    document .querySelector(".swiper")  .scrollTo( Math.round(e.srcElement.scrollLeft / (8.266667 * n)) * (8.266667 * n), 0);
                    
                    this.index = Math.round(e.srcElement.scrollLeft / (8.266667 * n));
           
              
           }, 200);
          
     },
       
      
   
    junm(type) {
      try {

                 window.webkit.messageHandlers.jump.postMessage( JSON.stringify({ type: type }));
         
        
      } catch {


                try {

                              window.android.goRecruit(type)?.false;

                } catch {

                  //

                }
      }
    },
  },
  mounted() {


        // 获取用户信息
        this.getUser().then(()=>{ 

      // 获取用户会员信息
       getLevel({id:this.$store.state.userDate.id}).then((res) => {


                if (res.data.code === 0) {
                 
                          this.level=res.data.data

                          this.level.pointsCurrent=Number(this.level.pointsCurrent)


                          if(this.level.pointsCurrent<this.level.gold){ 

                            this.level.levelName=0

                          }

                          if(this.level.pointsCurrent>=this.level.gold&&this.level.pointsCurrent<this.level.diamond){
                            
                            this.level.levelName=1

                          }
                          if(this.level.pointsCurrent>=this.level.diamond){ 

                            this.level.levelName=2

                          }
                          
    
                          this.$nextTick(()=>{ 

                                        // 初始化轮播图
                                      this.index = new Swiper(".swiper-container", {

                                                    slidesPerView: 'auto',

                                                    centeredSlides: true,

                                                    // slidesPerView: 1,

                                                    spaceBetween: 40,

                                                    breakpointsInverse: true,

                                                    // onSlideChangeEnd: function (mySwiper) {

                                                                //   console.log(mySwiper.activeIndex); // 切换结束时，告诉我现在是第几个slide

                                                                //   this.index=mySwiper.activeIndex

                                                                //  console.log(this.index);

                                                    // },
                                        });
                        
                                        // 轮播图的初始化位置
                                        this.index.slideTo(this.level.levelName, 1000, false)
                          });

                }
          })
    })

    
  },
  components: {
    Head,
  },

  beforeDestroy() {
    try {

            // 移除状态栏背景色
            window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({}));

            // 移除状态栏字体颜色
            window.webkit.messageHandlers.statusBarWhite.postMessage(JSON.stringify({status:0}))


    } catch (error) {

      //

    }
  },
};
</script>

<style lang="less" scoped>
.vip {
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  //   background: linear-gradient(135deg, #42404c 0%, #25201e 100%);
  // background-image: url('../../../assets/maker/bg_by.png');
  background-size: 100% 7.2rem;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .main::-webkit-scrollbar{ 
    display: none;
  }
  .main {
    overflow: auto;
    background-color:white;
    .main-box {
      padding-bottom: 0.6667rem;
      background-image: url("../../../assets/maker/bg_black.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }
  
    // .swiper {
    //   // width: 100%;
    //   // overflow-x: auto;
    //   // box-sizing: border-box;
    //   // height: 4.533333rem;
    //   // overflow-y: hidden;
    //   .swiper-box {
    //     display: flex;
    //     align-items: center;
    //     height: 4.533333rem;
    //     padding: 0 0.853333rem;
    //     width: 26.306667rem;
    //     box-sizing: border-box;
    //     .list {
    //       width: 8.266667rem;
    //       height: 4.533333rem;
    //       transform: scale(0.7);
    //       box-sizing: border-box;
    //       overflow: hidden;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //     }
    //   }
    // }
    // 

    // .suofang {
    //   //
    //   transition: 0.5 ease-in-out;
    // }

    .item {
      transform: scale(0.7);
      height: 4.533333rem;
      width: 8.266667rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-left: 0.373333rem;
      margin: auto;
      border-radius: 0.266667rem;
      padding-top: 3rem;
      position: relative;
      box-sizing: border-box;

      .lable {
        width: 1.493333rem;
        height: 0.626667rem;
        border-radius: 0.266667rem 0px 0.213333rem 0px;
        opacity: 0.7;

        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.333333rem;
        font-family: PingFangSC-Medium, PingFang SC;

        position: absolute;
        left: 0;
        top: 0;
      }
      .level {
        text-align: left;
        //    margin-top: 1.093333rem;
        font-size: 0.373333rem;
        font-family: PingFangSC-Medium, PingFang SC;
        margin-top: 0.266667rem;
        width: 100%;
      }

      .tip {
        color: #999da9;
        display: flex;
        font-size: 0.32rem;
        width: 100%;
        height: 0.666667rem;
        // align-items:center;

        // margin-bottom: 0.266667rem;
        span {
          font-size: 0.48rem;
          line-height: 0.466667rem;
        }
      }
      .range {
        height: 0.16rem;
        width: 4rem;
        position: relative;
        border-radius: 0.146667rem;
        opacity: 0.64;
        background: #c1c2cf;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
        overflow: hidden;
        .li {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;

          background-color: #1a0e51;
        }
      }
    }
    .item1 {
    
      height: 6.186667rem;
      width: 100%;
      background-image: url("../../../assets/roomImg/yin_icon.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .item2 {
     
     

      height: 6.186667rem;
      width: 100%;
      background-image: url("../../../assets/roomImg/jin_icon.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .item3 {
     

      height: 6.186667rem;
      width: 100%;
      background-image: url("../../../assets/roomImg/top_icon.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .box {
      width: 100%;

      //   background-color: white;
      padding-bottom: 0.206667rem;
      padding-top: 0.666667rem;
      margin-top: 0.666667rem;
      .title {
        color: #e4be68;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        font-size: 0.48rem;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: block;
          width: 1.066667rem;
          height: 0.053333rem;
          background: linear-gradient(
            90deg,
            rgba(243, 224, 161, 0.5) 0%,
            #e4be68 100%
          );
          border-radius: 0.106667rem;
          margin: 0 0.266667rem;
        }
      }
    }
    .warp {
      width: 100%;
      border-top: 0.266667rem solid #f6f6f6;
      background-color: white;
      padding: 0.4rem 0.4rem 0;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      box-sizing: border-box;

      .title {
        padding: 0.266667rem 0;
        font-size: 0.48rem;
        color: #333;
      }
      .footer {
        width: 100%;
        display: flex;
        // padding: .293333rem  .373333rem;
        .right {
          flex: 1;
          margin-left: 0.133333rem;
          height: 100%;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .top {
            width: 100%;
            height: 2.4rem;
            margin-bottom: 0.133333rem;
            background-image: url("../../../assets/maker/dianqi.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .list {
            width: 2.8rem;
            height: 2.8rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .list1 {
            background-image: url("../../../assets/maker/sufa.png");
            margin-right: 0.133333rem;
          }
          .list2 {
            background-image: url("../../../assets/maker/travel.png");
          }
        }

        .left {
          display: flex;
          width: 3.333333rem;
          // background-image: url("../../../assets/maker/food.png");
          background-size: 100% 100%;
          height: 5.333333rem;
        }
      }
    }
  }
  .swiper div {
    width: 2.666667rem;
    height: 2.666667rem;
  }
}
@keyframes mymove {
  0% {
    transform: scale(0.9375);
  }
  100% {
    transition: scale(1);
  }
}

.swiper-container{
         touch-action: none;
         box-sizing: border-box;
         width: 100%;
         padding-top: .4rem;
      

}
.swiper-slide {                // 默认设置slide宽度为屏幕的80%
       text-align: center;
       font-size: 18px;
        width: 6.266667rem !important;
        // height: 3.533333rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
       

     }
   
.swiper-slide-active{
  transition: 0.5s ease-out;
    transform: scale(1.3);
     display: flex;
    align-items: center;
    justify-content: center;
}
.yin {
      background-image: url("../../../assets/maker/vip_yin.png");
    }
    .jin {
      background-image: url("../../../assets/maker/vip_jin.png");
    }
    .zhuansi {
      background-image: url("../../../assets/maker/vip_zhuansi.png");
    }

  .add{ 
      animation: ani 1s ease-out;
       -webkit-animation:ani 1s ease-out;
  }

    @keyframes ani {
        0%{ 
            opacity: 0;
        }
       100% { 
          opacity: 1;
        }
    }
</style>